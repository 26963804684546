.create-project-container {
     position: absolute;
     top: 80px;
     width: 100%;
}
.error-message {
     text-align: center;
     margin-left: 20px;
     margin-right: 20px;

     font-family: Open sans;
     color: red;
}

.create-project-container > h1 {
     font-family: raleway;
     text-align: center;
     margin-bottom: 20px;
     margin-top: 20px;
}
.create-project-container > div {
     display: grid;
     /* flex-direction: column;/ */
     gap: 20px;
     justify-content: center;
     align-items: center;
}
.create-project-container > div > div {
     display: grid;

     width: 90vw;
     flex-direction: column;
     justify-content: center;
     align-items: center;
}

.create-project-input-container {
     display: grid;
     gap: 5px;
     width: 90%;
}
.create-project-input-container > label {
     font-family: Open sans;
     font-size: 15px;
     /* width: 40px; */
}
.create-project-input-container > input {
     width: 400px;
     border-radius: 6px;
     padding: 5px;
     outline: none;
     border: 1px solid #cecece;
}
.create-project-input-container > div > label {
     font-family: Open sans;
     font-size: 15px;
     /* width: 40px; */
}
.create-project-input-container > div > input {
     width: 400px;
     border-radius: 6px;
     padding: 5px;
     outline: none;
     border: 1px solid #cecece;
}

.add-tech-btn {
     border: none;
     padding: 5px 10px;
     background-color: #000;
     color: #fff;
     border-radius: 5px;
     position: absolute;
     margin-left: 10px;
}

.remove-tech-btn {
     border: none;
     padding: 5px 10px;
     background-color: red;
     color: #fff;
     border-radius: 5px;
     position: absolute;
     margin-left: 10px;
}

.create-project-container > button {
     border: none;
     padding: 10px 15px;
     border-radius: 5px;
     color: white;
     background-color: #000;
     display: table;
     margin: 0 auto;
     margin-top: 20px;
     cursor: pointer;
}

@media screen and (max-width: 500px) {
     .create-project-input-container > input {
          width: 230px;
     }
     .create-project-input-container > div > input {
          width: 230px;
     }
}
