.reset-password-container {
     position: absolute;
     top: 80px;
     height: 100%;
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     margin-bottom: 20px;
}
.reset-password-content {
     /* border: 1px solid; */
     position: relative;
     padding: 100px;
     box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
          rgba(0, 0, 0, 0.24) 0px 1px 2px;
     border-radius: 10px;
}
.reset-password-content > h2 {
     font-family: Raleway;
     text-align: center;
     margin-bottom: 20px;
}
.reset-password-content > form > button {
     margin-top: 5px;
     margin-bottom: 10px;
}

.reset-infos{
    margin-top: 20px;
    text-align:center;
    font-size: 16px;
}
.reset-password-content > div {
     border: 1px solid;
     display: flex;
     flex-direction: column;
}

.reset-text{
    font-family: roboto;
    text-align: center;
}

.reset-signin {
     font-family: Raleway;
}

@media screen and (max-width: 500px) {
     .reset-password-content {
          /* border: 1px solid; */
          padding: 50px 30px;
     }
}
