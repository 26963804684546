.loader-container {
     width: 100%;
     height: 100vh;
     justify-content: center;
     align-items: center;
     position: absolute;
     display: flex;
     z-index: 1;
     background-color: #fff;
}
