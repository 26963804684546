@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

nav {
     background-color: rgb(27, 23, 23);
     height: 70px;
     display: flex;
     justify-content: space-evenly;
     align-items: center;
     position: fixed;
     width: 100%;
     z-index: 1;
}
nav > p {
     text-align: center !important;
     font-size: 25px;
}
nav > p > a {
     text-align: center;
     font-family: Raleway;
     color: white;
     text-decoration: none;
     transform: translate(-50%, 50%);
}
nav > p > a > span {
     font-style: oblique;
     vertical-align: super;
     margin-right: 3px;
}
nav > div > input {
     width: 350px;
     padding: 10px 15px;
     border-radius: 10px;
     border: none;
     outline: none;
}

nav > div > i {
     position: absolute;
     font-size: 25px;
     color: black;
     background-color: #fff;
     padding: 6px 7px;
     border-radius: 20px;
}

nav > button {
     background-color: #fff;
     padding: 6px 15px;
     border: none;
     border-radius: 5px;
     font-family: Roboto;
     /* color: white; */
}

@media screen and (max-width: 500px) {
     nav {
          justify-content: space-between;
          padding: 10px;
     }
     nav > div > input {
          width: 100px;
          padding: 8px 5px;
     }
     
     nav > div {
          padding: 8px 5px;
         
          margin-right: 20px;
     }
     nav > div > i {
          font-size: 20px;
    
     }
}
