.render-loading {
     position: absolute;
     top: 80px;
}

.single-project-container {
     position: absolute;
     top: 70px;
     width: 100%;
     height: 100%;
}

.single-project-container > p {
     font-size: 2em;
     font-family: Raleway;
     text-align: center;
     margin: 20px;
}

.single-project-content {
     margin: 20px;
     display: flex;
     flex-direction: column;
     align-items: center;
}

.other-image-container {
     display: flex;
     gap: 10px;
     padding: 10px;
     flex-wrap: wrap;
}
.other-image-container > a > img {
     height: 50px;
     object-fit: contain;
     width: 50px;
}

.single-project-content > img {
     width: 150px;
     height: 150px;
     object-fit: contain;
}

.project-description {
     font-family: Open sans;
     text-align: center;
     margin: 20px;
}
.project-technology {
     font-family: Raleway;
     display: flex;
     flex-wrap: wrap;
     align-items: center;
     gap: 5px;
     margin-bottom: 10px;
 
     justify-content: center;
     
}
.project-technology > span {
     font-family: Open sans;
}

.git-link {
     color: initial;
     font-size: 25px;
}

.update-delete-container {
     display: flex;
     align-items: center;
     justify-content: center;
}
.update-delete-container > div {
     display: flex;
     justify-content: space-between;
     margin-top: 30px;
     width: 60%;
}
.update-delete-container > div > button {
     padding: 5px 20px;
}
.update-delete-container > div > button:nth-of-type(1) {
     color: white;
     background-color: black;
     border: none;
     border-radius: 5px;
     font-family: Open sans;
     font-size: 16px;
}
.update-delete-container > div > button:nth-of-type(2) {
     font-size: 16px;
     color: white;
     font-family: Open sans;
     border-radius: 5px;
     background-color: red;
     border: none;
}

.no-project {
     position: absolute;
     top: 80px;
     text-align: center;
     width: 100%;
     font-size: 30px;
     font-family: Open sans;
}

.error-image-container {
     position: absolute;
     top: 80px;
     width: 100%;
     object-fit: contain;
     height: 150px;
     left: 0;
     right: 0;
}

